
.App {
  text-align: center;
}

.App-header {
  background-image: url(./images/QRCode.jpeg);
  background-repeat:no-repeat;
  background-position: 50% 38% ;
  background-size: 52vmin;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.images {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
}

.contact {
  color: black;
  text-decoration: none;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

@-moz-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 

@-webkit-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
.blink-image {
  -moz-animation: blink normal 20s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 20s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 20s infinite ease-in-out; /* IE */
  animation: blink normal 20s infinite ease-in-out; /* Opera and prob css3 final iteration */
  height: 70vmin;
}

.social-container {
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: #E1306C;
}

a.whatsapp {
  color: #25D366;
}


.myButton {
	box-shadow:inset 0px 18px 0px -2px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:42px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;

	text-decoration:none;
	text-shadow:0px -1px 14px #ffffff;
}
.myButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.myButton:active {
	position:relative;
	top:1px;
}

        